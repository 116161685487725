// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #dc4c4c;
$orange: #f6993f;
$yellow: #dab54f;
$green: #589f60;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$black: #000000;
$white: #ffffff;
$gray: #9a9a9a;
$gray-dark: #3a3a3a;

$colors: (
        "blue":       $blue,
        "indigo":     $indigo,
        "purple":     $purple,
        "pink":       $pink,
        "red":        $red,
        "orange":     $orange,
        "yellow":     $yellow,
        "green":      $green,
        "teal":       $teal,
        "cyan":       $cyan,
        "black":      $black,
        "white":      $white,
        "gray":       $gray,
        "gray-dark":  $gray-dark
);

$gradient: linear-gradient(180deg, rgba($white, .15), rgba($white, 0));